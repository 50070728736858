import { inject, Injectable } from "@angular/core";
import { IIndexable } from "../../../core/base/indexable.interface";
import { NotificationDTO } from "../model/notification.dto";
import { EpochDateMapper } from "./epoch-date.mapper";
import { Mapper } from "../../../core/base/mapper";

@Injectable({
    providedIn: "root",
})
export class JsonNotificationMapper extends Mapper<unknown, NotificationDTO> {
    private epochDateMapper: EpochDateMapper = inject(EpochDateMapper);

    /**
     * Maps a JSON object to a NotificationDTO.
     * @param json The JSON object to map from.
     * @returns The mapped NotificationDTO.
     */
    mapFrom(json: IIndexable): NotificationDTO {
        this.logger.debug(`Mapping JSON to notification DTO: ${json}`);
        const {
            notificationID,
            createdOn,
            triggeredBy,
            userName,
            read,
            eventID,
            recordID,
            ...rest
        } = json;

        const dto: NotificationDTO = {
            ...new NotificationDTO(),
            ...rest,
            id: notificationID,
            creationDate: this.epochDateMapper.mapFrom(createdOn),
            sender: triggeredBy,
            receiver: userName,
            isRead: read,
            eventId: eventID,
            recordId: recordID,
        };

        return dto;
    }

    /**
     * Maps a NotificationDTO to a JSON object.
     * @param notificationDto The NotificationDTO to map from.
     * @returns The mapped JSON object.
     */
    mapTo(dto: NotificationDTO): IIndexable {
        this.logger.debug(`Mapping notification DTO to JSON: ${dto}`);
        const {
            id,
            creationDate,
            sender,
            receiver,
            isRead,
            eventId,
            recordId,
            ...rest
        } = dto;

        const json: IIndexable = {
            ...rest,
            notificationID: id,
            createdOn: this.epochDateMapper.mapTo(creationDate),
            triggeredBy: sender,
            userName: receiver,
            read: isRead,
            eventID: eventId,
            recordID: recordId,
        };

        return json;
    }
}
