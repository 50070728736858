import { NotificationStatus } from "../../enums/notification-status.enum";

export class NotificationDTO {
    /**
     * The ID of the notification.
     */
    id: number = 0;

    /**
     * The date the notification was created.
     */
    creationDate: Date = new Date();

    /**
     * The subject of the notification.
     */
    subject: string = "";

    /**
     * The sender of the notification.
     */
    sender: string = "";

    /**
     * The receiver of the notification.
     */
    receiver: string = "";

    /**
     * The message body of the notification.
     */
    message: string = "";

    /**
     * The read status of the notification.
     */
    isRead: boolean = false;

    /**
     * The status of the notification.
     */
    status: NotificationStatus = NotificationStatus.Open;

    /**
     * The event ID of the notification.
     */
    eventId: number = 0;

    /**
     * The event type of the notification.
     */
    eventType: string = "";

    /**
     * The record ID associated with the notification.
     */
    recordId: number = 0;

    /**
     * The record label associated with the notification.
     */
    recordLabel: string = "";

    /**
     * The data list name associated with the notification.
     */
    listName: string = "";
}
