import { Injectable } from "@angular/core";
import { Mapper } from "../../../core/base/mapper";

@Injectable({
    providedIn: "root",
})
export class EpochDateMapper extends Mapper<string, Date> {
    /**
     * Transforms a string to a Date object.
     * @param param The string to transform into a Date object.
     * @returns A Date object representing the string.
     */
    override mapFrom(param: string): Date {
        this.logger.debug(`Mapping epoch time to Date: ${param}`);
        return this.toDate(param);
    }

    /**
     * Transforms a Date object to a string.
     * @param param The Date object to transform into a string.
     * @returns A string representing the Date object.
     */
    override mapTo(param: Date): string {
        this.logger.debug(`Mapping Date to epoch time: ${param}`);
        return this.fromDate(param);
    }

    /**
     * Transforms an epoch time to a Date object.
     * @param value The value to transform.
     * @returns The transformed value.
     */
    private toDate(value: string): Date {
        this.logger.debug(`Transforming epoch time to Date: ${value}`);

        let epochDate: Date = new Date(0);

        const date: string = value
            .replace("/Date(", "")
            .replace(")/", "")
            .replace("+0000", "");
        const epoch: number = Number(date);
        epochDate = new Date(epoch);

        if (!this.isValidDate(epochDate)) {
            this.logger.error(
                `Invalid date format: ${value}, expected: /Date(epoch+0000)/`
            );
            throw new Error(
                `Invalid date format: ${value}, expected: /Date(epoch+0000)/`
            );
        }

        this.logger.debug(
            `Transformed epoch time to Date: ${JSON.stringify(epochDate)}`
        );

        return epochDate;
    }

    /**
     * Transforms a Date object to an epoch time.
     * @param value The value to transform.
     * @returns The transformed value.
     */
    private fromDate(value: Date): string {
        this.logger.debug(`Transforming Date to epoch time: ${value}`);
        const milliseconds: number =
            Date.parse(value.toString()) + value.getMilliseconds();
        this.logger.debug(`Transformed Date to epoch time: ${milliseconds}`);
        return `/Date(${milliseconds}+0000)/`;
    }

    /**
     * Checks if a date is valid.
     * @param date The date to check.
     * @returns True if the date is valid, false otherwise.
     */
    private isValidDate(date: Date): boolean {
        return date instanceof Date && !isNaN(date.getTime());
    }
}
